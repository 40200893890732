runExec<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="grid1"
          title="평가항목"
          tableId="grid1"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid1.columns"
          :data="grid1.data"
          :gridHeight="gridheight"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
          <!-- <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && !disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="selfInspectionResultItemMstId"
                ibmTaskTypeCd="ITT0000035"
                ibmTaskUnderTypeCd="ITTU000060"
                @imprChange="imprChange"
              />
            </template>
          </template> -->
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="grid2"
          title="구분"
          tableId="grid2"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid2.columns"
          :data="grid2.data"
          :gridHeight="gridheight"
          @rowClick="rowClick2"
          :isExcelDown="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="grid3"
          title="평가항목별 세부항목"
          tableId="grid3"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid3.columns"
          :data="grid3.data"
          :editable="editable && !disabled"
          :gridHeight="gridheight"
          :isExcelDown="false"
        >
          <template slot="table-button">
            <div>
              <q-btn-group outline>
                <c-btn v-if="editable && !disabled" label="LBLSAVE" icon="save" @btnClicked="saverow" />
              </q-btn-group>
            </div>
          </template>
          <template slot="suffixTitle">
            <template>
              <div v-if="isShowDept" class="header-input">
                <q-chip
                  v-for="(dept, idx) in planData.deptList"
                  outline square
                  :key="idx"
                  :removable="false"
                  :color="dept.selected ? 'primary' : 'grey-6'"
                  text-color="white"
                  icon="memory"
                  :selected.sync="dept.selected"
                  :label="dept.deptName"
                  v-model="dept.check"
                  @update:selected="state => selectedDept(state, dept, idx)"
                >
                </q-chip>
              </div>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'run-exec',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
    planData: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
        processList: [],
        equipList: [],
        deptList: [],
        execList: [],
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      gridheight: '',
      grid1: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            style: 'width:130px',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: 'customCol',
          //   field: 'customCol',
          //   label: '개선/진행상태',
          //   align: 'center',
          //   style: 'width:200px',
          //   type: 'custom',
          //   sortable: false
          // },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'itemTypeName',
            field: 'itemTypeName',
            label: '구분',
            style: 'width: 130px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid3: {
        columns: [],
        data: [],
      },
      list1Url: '',
      list2Url: '',
      list3Url: '',
      updateUrl: '',
      editable: true,
      selectedLastRow: {},
      isShowDept: false,
      deptFilter: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.param.selfInspectionStatusCd != 'SISC000002' || this.planData.approvalStatusCd === 'ASC0000001';
    },
    // // 개선요청 내용 전달
    // requestContentsCols() {
    //   return ['itemMstName'];
    // },
    // // 개선요청 Component
    // imprComponent() {
    //   let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    //   return components;
    // },
  },
  methods: {
    init() {
      this.gridheight = (window.innerHeight - 208) + 'px';
      // code setting
      this.editable = this.$route.meta.editable;
      this.list1Url = selectConfig.sop.pca.item.revs.url;
      // this.list1Url = selectConfig.sop.pca.resultItemMst.url;
      this.list2Url = selectConfig.sop.pca.type.list.url;
      this.list3Url = selectConfig.sop.pca.itemsresult.mst.url;
      this.updateUrl = transactionConfig.sop.pca.result.item.save.url;

      this.$comm.getComboItems('SELF_INSPECTION_RESULT_CD').then(_result => {
        this.grid3.columns = [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'No.',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '세부항목',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'selfInspectionResultCd',
            field: 'selfInspectionResultCd',
            label: '평가',
            align: 'center',
            setHeader: true,
            type: 'select',
            comboItems: _result,
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'selfInspectionRemark',
            field: 'selfInspectionRemark',
            label: '평가 근거',
            align: 'left',
            type: 'textarea',
            style: 'width: 250px',
            sortable: false,
          },
          {
            name: 'answerUserName',
            field: 'answerUserName',
            label: '답변자',
            align: 'center',
            sortable: false,
            type: 'user',
            userId: 'answerUserId',
            value: '',
            setHeader: true,
            style: 'width:100px',
          },
        ]
        this.getList();
      });
    },
    getList() {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {
        sysRevision: this.param.sysRevision,
        // selfInspectionResultId: this.param.selfInspectionResultId
      };
      this.$http.request((_result) => {
        this.selectedLastRow = {};
        this.grid1.data = _result.data;
      },);
    },
    rowClick(row) {
      this.selectedLastRow = {};
      this.$http.url = this.list2Url;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemMstId: row.selfInspectionItemMstId
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
        this.grid3.data = [];
        
        if (this.grid2.data.length === 1) {
          this.rowClick2({selfInspectionItemTypeId: this.grid2.data[0].selfInspectionItemTypeId, itemTypeName: this.grid2.data[0].itemTypeName});
        }
      },);
    },
    rowClick2(row) {
      this.isShowDept = true
      this.selectedLastRow = row;
      this.$http.url = this.list3Url;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemTypeId: row.selfInspectionItemTypeId,
        selfInspectionResultId: this.param.selfInspectionResultId,
        deptCd: this.deptFilter ? this.deptFilter : this.planData.deptList[0].deptCd,
      }
      this.$http.request((_result) => {
        this.grid3.data = _result.data;
      },);
    },
    saverow() {
      let saveData = this.grid3.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.selfInspectionResultId = this.param.selfInspectionResultId;
        item.deptCd = this.deptFilter ? this.deptFilter : this.planData.deptList[0].deptCd;
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      // selfInspectionResultId
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.rowClick2(this.selectedLastRow);
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    // 개선창 닫기 후
    imprChange() {
      // this.getList();
      this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
    },
    selectedDept(state, dept, idx) {
      this.deptFilter = dept.deptCd;
      if (state) {
        this.planData.deptList.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.planData.deptList[idx], 'selected', true)
      }
      this.rowClick2(this.selectedLastRow)
    },
  }
};
</script>
<style lang="sass">
.header-input
  float: right !important
  margin-top: 0x
  margin-left: 10px
  .customSelect
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
</style>